import { rgba } from "polished";
import { breakpoints } from "./breakpoints";

const defaultColors = {
  primary: "#f04037",
  secondary: "#5454d4",
  white: "#FBF8F5",
  dark: "#19191b",
  black: "#052424",
  yellow: "#fedc5a",
  ash: "#413e65",
  green: "#77bf41",
  info: "#0e567c",
};

const brandColors = {
  "olive-100": "#e1e6df",
  "olive-200": "#b2c4b4",
  "olive-300": "#798770",
  "teal-100": "#d6deda",
  "teal-200": "#618a8a",
  "teal-300": "#024040",
  "blue-100": "#d6d9d5",
  "blue-200": "#6b87a0",
  "blue-300": "#485c65",
  "orange-100": "#f0e5d4",
  "orange-200": "#dbaa65",
  "orange-300": "#d49a32",
  "red-100": "#eee2d7",
  "red-200": "#eaccc4",
  "red-300": "#d18065",
  white: "#fbf8f5",
  black: "#052424",
};

const colors = {
  primary: brandColors["teal-300"],
  secondary: brandColors["olive-200"],
  light: brandColors.white,
  lightShade: brandColors["teal-100"],
  dark: brandColors.black,
  darkShade: brandColors["blue-300"],
  ash: brandColors["teal-300"],
  bg: brandColors.white,
  border: brandColors["teal-200"],
  shadow: brandColors["teal-200"],
  heading: brandColors["teal-300"],
  text: brandColors["teal-300"],
  warning: brandColors["red-100"],
  success: brandColors["olive-200"],
  info: brandColors["blue-300"],
  infoLight: brandColors["blue-100"],
  red: brandColors["red-300"],
  orange: brandColors["orange-200"],

  modes: {
    dark: {
      primary: defaultColors.primary,
      secondary: defaultColors.secondary,
      light: defaultColors.white,
      lightShade: rgba(defaultColors.white, 0.75),
      dark: defaultColors.dark,
      darkShade: rgba(defaultColors.dark, 0.75),
      ash: defaultColors.ash,
      bg: defaultColors.dark,
      border: rgba(defaultColors.ash, 0.115),
      shadow: rgba(defaultColors.ash, 0.175),
      heading: defaultColors.white,
      text: rgba(defaultColors.white, 0.75),
      warning: defaultColors.yellow,
      success: defaultColors.green,
      info: defaultColors.info,
    },
  },
};

const theme = {
  initialColorModeName: "light",
  colors: colors,
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  breakpoints: [
    `${breakpoints.sm}px`,
    `${breakpoints.md}px`,
    `${breakpoints.lg}px`,
    `${breakpoints.xl}px`,
  ],
};

export default theme;
