import React from "react";
import styled from "styled-components";
import { color, space, typography, shadow } from "styled-system";
import { device } from "../../utils";

const LogoTitle = styled.h2`
  font-weight: 700;
  letter-spacing: 0px;
  font-size: 36px;
  line-height: 54px;
  margin-bottom: 12px;
  color: ${({ dark, theme }) =>
    dark ? theme.colors.light : theme.colors.dark}!important;

  @media ${device.sm} {
    font-size: 44px;
    line-height: 58px;
  }

  @media ${device.lg} {
    font-size: 48px;
    line-height: 65px;
    margin-bottom: 0px;
  }

  ${color};
  ${space};
  ${typography};
  ${shadow};
`;

const SectionTitle = styled.h2`
  font-weight: 700;
  letter-spacing: -0.8px;
  font-size: 40px;
  line-height: 54px;
  margin-bottom: 16px;

  @media ${device.sm} {
    font-size: 50px;
    line-height: 62px;
  }

  @media ${device.lg} {
    font-size: 60px;
    line-height: 70px;
    margin-bottom: 30px;
  }

  ${color};
  ${space};
  ${typography};
  ${shadow};
`;

const SmallSectionTitle = styled.h2`
  font-weight: 700;
  font-size: 40px;
  line-height: 54px;
  margin-bottom: 16px;

  ${color};
  ${space};
  ${typography};
  ${shadow};
`;

const HeroTitle = styled(SectionTitle)`
  letter-spacing: -0.2px;
  font-size: 46px;
  line-height: 52px;
  margin-bottom: 30px;

  @media ${device.sm} {
    font-size: 60px;
    line-height: 68px;
  }

  @media ${device.md} {
    font-size: 44px;
    line-height: 50px;
  }
`;

const CardTitle = styled.h4`
  font-size: 21px;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 28px;
  ${color};
  ${space};
  ${typography};
  ${shadow};
`;

const SubTitle = styled.h4`
  font-size: 21px;
  font-family: SofiaPro;
  font-weight: 500;
  letter-spacing: -0.66px;
  line-height: 28px;
  ${color};
  ${space};
  ${typography};
  ${shadow};
`;

const Title = ({ variant, ...rest }) => {
  let TitleStyled = SectionTitle;

  switch (variant) {
    case "card":
      TitleStyled = CardTitle;
      break;
    case "hero":
      TitleStyled = HeroTitle;
      break;
    case "subtitle":
      TitleStyled = SubTitle;
      break;
    case "logo":
      TitleStyled = LogoTitle;
      break;
    case "small-section":
      TitleStyled = SmallSectionTitle;
      break;
    default:
      TitleStyled = SectionTitle;
  }

  return <TitleStyled color="heading" {...rest} />;
};

export default Title;
