export const menuItems = [
  {
    name: "",
    label: "What We Do",
    items: [
      { name: "", label: "Home" },
      { name: "faq", label: "Frequently Asked Questions" },
    ],
  },
  {
    name: "who",
    label: "Who We Are",
  },
  {
    name: "where",
    label: "Where We're Going",
  },
  {
    name: "https://urlgeni.us/app_",
    isExternal: true,
    label: "Get Petaler",
  },
];
